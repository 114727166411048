import { render, staticRenderFns } from "./TeamListItem.vue?vue&type=template&id=28e9ca0d&scoped=true&"
import script from "./TeamListItem.vue?vue&type=script&lang=js&"
export * from "./TeamListItem.vue?vue&type=script&lang=js&"
import style0 from "./TeamListItem.vue?vue&type=style&index=0&id=28e9ca0d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28e9ca0d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VIcon,VSpacer})
